import { render, staticRenderFns } from "./ValidateRegister.vue?vue&type=template&id=16865522&scoped=true&"
import script from "./ValidateRegister.vue?vue&type=script&lang=js&"
export * from "./ValidateRegister.vue?vue&type=script&lang=js&"
import style0 from "./ValidateRegister.vue?vue&type=style&index=0&id=16865522&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16865522",
  null
  
)

export default component.exports